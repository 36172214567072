import { Controller } from "@hotwired/stimulus";
import Plugin from "@revolist/revogrid-column-date";


// Connects to data-controller="dinamic_table"
export default class extends Controller {
    static targets = ["table", "initialData", "changedData", "removedIds", "formData", "searchInput", "selectInput", "totalItens"];
    static values = { changed: [], rows: [], removedIds: [] }

    connect() {
        this.build();
    }

    build() {
        document.addEventListener('updateDinamicTable', evt => this.updateDinamicTable(evt))
        const grid = this.tableTarget;
        const initialData = this.getInitialDataValue();
        const doDelete = (props) => {
            if (confirm("Deseja realmente remover esta linha")) {
                this.removedIdsValue = [...this.removedIdsValue, props.model.id];
                grid.source = props.data.filter(object => object.id !== props.model.id);
                this.removedIdsTarget.value = JSON.stringify(this.removedIdsValue);
                this.updateCounter(grid.source.length)
                grid.dispatchEvent(new Event('afterEdit'));
            }
        };

        if (initialData.columns) {
            let columns = initialData.columns;

            if (initialData.enabled_to_edit) {
                columns = [...initialData.columns,
                {
                    size: 200,
                    cellTemplate: (h, props) => {
                        let linkEdit = h("a", {
                            href: initialData.edit_urls[props.model.id],
                            "data-turbo": "true",
                            "data-turbo-frame": "remote_modal"
                        }, "Pesquisar Exncm");
                        let linkDelete = h("a", { onClick: () => doDelete(props) }, "Remover");
                        return h("div", {}, linkEdit, " | ", linkDelete);
                    },
                    pin: 'colPinEnd'
                },
                ];
            }

            this.rowsValue = initialData.rows;
            grid.columns = columns;
            grid.source = initialData.rows;
            grid.range = true;
            grid.filter = false;
            grid.sort = true;
            grid.resize = "true";
            grid.rowClass = "myRowClass";
            grid.columnTypes = { 'date': new Plugin() };
            if (!initialData.enabled_to_edit) grid.readonly = true;
        }
    }

    change(e) {
        const getDifference = (array1, array2) => {
            return array1.filter(object1 => {
                return array2.some(object2 => {
                    return object1.id === object2.id && (JSON.stringify(object1) !== JSON.stringify(object2));
                });
            });
        }
        const diff = getDifference(e.target.source, this.rowsValue);
        this.changedDataTarget.value = JSON.stringify(diff);
        this.save();
    }

    save(_e) {
        let initialDataValue = this.getInitialDataValue()
        initialDataValue.rows = this.getActualStateOfTable()
        this.initialDataTarget.value = JSON.stringify(initialDataValue);
        this.updateCounter(initialDataValue.rows.length)
        this.formDataTarget.submit();
    }

    cancel(_e) {
        this.tableTarget.source = this.getInitialDataValue().rows;
        this.removedIdsValue = []
        this.changedDataTarget.value = ""
        this.updateCounter(this.tableTarget.source.length)
    }

    // Devolve o estado atual da tabela em formato de lista, sem as linhas que foram deletadas nesta lista.
    getActualStateOfTable() {
        let actualRowState = this.getInitialDataValue().rows;
        if (this.hasChangedDataTarget && this.changedDataTarget.value !== "") {

            const map = new Map();

            actualRowState.forEach(staticRowsKey => {
                map.set(staticRowsKey.id, staticRowsKey)
            })
            const changedRows = JSON.parse(this.changedDataTarget.value)

            changedRows.forEach(changedRowsKey => {
                map.set(changedRowsKey.id, changedRowsKey)
            });
            actualRowState = Array.from(map.values());
        }

        let removedIds = this.removedIdsValue
        return actualRowState.filter(row => !removedIds.includes(row.id));
    }

    search(e) {
        const grid = this.tableTarget;
        const inputsWithValue = this.searchInputTargets.filter((input) => {
            return input.value !== undefined && input.value.trim() !== ""
        })

        let actualStateOfTable = this.getActualStateOfTable()

        if (inputsWithValue.size !== 0) {
            inputsWithValue.forEach((input) => {
                const filterTerm = input.value.toLowerCase();
                const columnKey = input.name;

                if(input.type === 'select-one'){
                    actualStateOfTable = actualStateOfTable.filter((item) => {
                        const columnValue = item[columnKey]
                        return columnValue !== undefined && columnValue.toString().toLowerCase() === filterTerm;
                    });
                }else{
                    actualStateOfTable = actualStateOfTable.filter((item) => {
                        const columnValue = item[columnKey]
                        return columnValue !== undefined && columnValue.toString().toLowerCase().includes(filterTerm);
                    });
                }

            })
        }

        grid.source = actualStateOfTable;
        this.updateCounter(grid.source.length)
    }

    updateCounter(tamanho) {
        this.totalItensTarget.innerHTML = `Total de Itens: ${tamanho}`
    }

    getInitialDataValue() {
        const initialDataValue = JSON.parse(this.initialDataTarget.value.replace(/null/g, '""'));
        initialDataValue.rows.forEach(row => {
            if(row.founded_on_base === true || row.founded_on_base === "Encontrado"){
                row.founded_on_base = "Encontrado"
            }else{
                row.founded_on_base = "Não foi encontrado"
                row.myRowClass = "alert-warning"
            }
        })
        return initialDataValue;
    }

    updateDinamicTable(event) {
        const grid = this.tableTarget;

        grid.source = this.getActualStateOfTable().map(row => {
            let updatedItems = event.detail
            let updatedRow = updatedItems[row.id]
            if (updatedRow !== undefined) {
                updatedRow.forEach(changes => {
                    row[changes.field] = changes.value
                })
            }
            return row;
        });

        grid.dispatchEvent(new Event('afterEdit'));
    };

}
