import { Controller } from "@hotwired/stimulus"

// Connects to accounts--rule-components--table"
export default class extends Controller {
  static targets = ["currentIds"]
  connect() {}
  onCheckboxChange({ detail: { pageValues }}) {
    let selectedIds = pageValues;

    if(this.hasCurrentIdsTarget) {
      this.currentIdsTarget.value = selectedIds;
    } 
  }
}
